import React from 'react';
import NavIcon from './NavIcon/NavIcon';
import { faQuestion, faHouseChimney } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import style from './NavIcons.module.scss?module';
import { faStreetView } from '@fortawesome/pro-solid-svg-icons';
import { useUser } from '../../../../Context/UserContext';
import { useHandleLeaveGuidedTour } from '../../hooks/useHandleLeaveGuidedTour';

const NavIcons = () => {
  const { stopGuidedTour } = useHandleLeaveGuidedTour();
  const userContext = useUser();

  if (userContext.loading) {
    return null;
  }

  return (
    <span className={style.Icons}>
      <NavIcon
        onClick={stopGuidedTour}
        link="/virtual-trade-show/lobby"
        icon={faHouseChimney}
        text="Lobby"
      />
      {userContext.user && (
        <NavIcon
          link="/virtual-trade-show/exhibition"
          icon={faStreetView}
          text="Exhibition"
        />
      )}
      <NavIcon
        onClick={stopGuidedTour}
        link="/virtual-trade-show/information"
        icon={faQuestion}
        text="Information"
      />
    </span>
  );
};
export default NavIcons;
