import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { matchMultiplePaths } from '../../Helper/matchMultiplePaths';
import FixedContainer from './Abstracts/FixedContainer/FixedContainer';
import NavIcons from './Abstracts/NavIcons/NavIcons';
import Main from './Main/Main';
import TopRightContents from './TopRightContents/TopRightContents';
import './VirtualTradeShow.scss';
import { getRequest } from '../../Helper/ApiClient';
import { VtsInterface } from '../../../Interface/Vts';
import LogoImage from './Abstracts/LogoImage/LogoImage';
import GuidedTour from './Main/GuidedTour/GuidedTour';
import { useAtomValue } from 'jotai';
import { startGuidedTourAtom } from './Atoms/vtsAtoms';

export const VtsContext = React.createContext<{
  vts: VtsInterface | undefined;
}>({
  vts: undefined,
});

const VirtualTradeShow = () => {
  const isStartedGuidedTour = useAtomValue(startGuidedTourAtom);
  const matchAdminPath = matchMultiplePaths(['/virtual-trade-show/admin/*']);
  const [searchParams, setSearchParams] = useSearchParams();
  const vtsRequest = getRequest<VtsInterface>(
    '/api/onlineshop/vts' +
      (searchParams.toString().length > 0 ? '?' + searchParams.toString() : '')
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=ef7db493-3544-4194-a2f8-045d9dad3978';
    script.async = true;
    script.id = 'ze-snippet';
    document.body.appendChild(script);

    // Clean up the script element when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="vts"
      style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}
    >
      <VtsContext.Provider value={{ vts: vtsRequest.data }}>
        <Main />
        {!matchAdminPath && (
          <>
            {!isStartedGuidedTour && (
              <>
                <FixedContainer position={'topLeft'}>
                  <LogoImage />
                </FixedContainer>
                <FixedContainer position={'topRight'}>
                  <TopRightContents />
                </FixedContainer>
              </>
            )}
            <FixedContainer position={'bottomCenter'}>
              <NavIcons />
            </FixedContainer>
            <FixedContainer
              style={{ left: '60%', bottom: '45dvh' }}
              position="bottomCenter"
            >
              <GuidedTour />
            </FixedContainer>
          </>
        )}
      </VtsContext.Provider>
    </div>
  );
};
export default VirtualTradeShow;
