import { useSetAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { startGuidedTourAtom } from '../Atoms/vtsAtoms';

export const useHandleLeaveGuidedTour = () => {
  const navigate = useNavigate();
  const startGuidedTour = useSetAtom(startGuidedTourAtom);

  const stopGuidedTour = () => {
    startGuidedTour(false);
    navigate('/virtual-trade-show/lobby');
  };

  return {
    stopGuidedTour,
  };
};
