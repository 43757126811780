import * as React from "react";
// @ts-ignore
import style from "./FixedContainer.module.scss?module";

interface FixedContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  position: "topLeft" | "topRight" | "bottomCenter" | 'topCenter';
}

const FixedContainer: React.FC<FixedContainerProps> = ({
  position,
  children,
  ...rest
}) => {
  let positionClass = "";

  switch (position) {
    case "topLeft":
      positionClass = style.TopLeft;
      break;
    case "topRight":
      positionClass = style.TopRight;
      break;
    case "bottomCenter":
      positionClass = style.BottomCenter;
      break;
    case "topCenter":
      positionClass = style.TopCenter;
      break;
  }

  return (
    <div {...rest} className={[style.FixedContainer, positionClass].join(" ")}>
      {children}
    </div>
  );
};

export default FixedContainer;
