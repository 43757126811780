import React, { FC, HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../../Abstracts/Button/Button';
import FixedContainer from '../../Abstracts/FixedContainer/FixedContainer';
import {
  showGuidedTourConsentCardAtom,
  startGuidedTourAtom,
  currentGuidedTourStepAtom,
} from '../../Atoms/vtsAtoms';
import { Stepper, Step } from 'react-form-stepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftFromBracket } from '@fortawesome/pro-light-svg-icons';
import { useHandleLeaveGuidedTour } from '../../hooks/useHandleLeaveGuidedTour';
//@ts-ignore
import styles from './GuidedTour.module.scss?module';

const GuidedTour = () => {
  const startGuidedTour = useAtomValue(startGuidedTourAtom);
  const showGuidedTourConsentCard = useAtomValue(showGuidedTourConsentCardAtom);
  return (
    <>
      {showGuidedTourConsentCard && !startGuidedTour && (
        <GuidedTourConsentCard />
      )}
      {startGuidedTour && <GuidedTourSteps />}
    </>
  );
};

export default GuidedTour;

const GuidedTourSteps = () => {
  const currentStep = useAtomValue(currentGuidedTourStepAtom);
  const firstLoad = useRef(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { stopGuidedTour } = useHandleLeaveGuidedTour();

  //check ob der user woanders clickt, dann beende Guided Tour
  useEffect(() => {
    if (!firstLoad.current) {
      const currentData = GuidedTourStepsData[currentStep];
      if (location.pathname !== currentData.url) {
        stopGuidedTour();
      }
    }
    firstLoad.current = false;
  }, [location.pathname]);

  // navigiere zu den nächsten oder vorherigen step im Guided Tour
  useEffect(() => {
    const currentData = GuidedTourStepsData[currentStep];
    if (currentData) {
      navigate(currentData.url);
    }
  }, [currentStep]);

  return (
    <>
      <StepsControllBar />
      <SpeechBubbleCard className="fs-5">
        {GuidedTourStepsData[currentStep].content}
      </SpeechBubbleCard>
    </>
  );
};

const GuidedTourConsentCard = () => {
  const setShowGuidedTourConsentCard = useSetAtom(
    showGuidedTourConsentCardAtom
  );
  const setStartGuidedTour = useSetAtom(startGuidedTourAtom);
  return (
    <SpeechBubbleCard>
      <span className="fs-5 fw-bold">
        Hi, I'm NaskorBot! Do
        <br />
        you want to start the
        <br />
        Guided Tour?
      </span>
      <Button
        className="rounded-3"
        onClick={() => {
          setStartGuidedTour(true);
          setShowGuidedTourConsentCard(false);
        }}
      >
        Yes
      </Button>
      <Button
        className="rounded-3"
        onClick={() => setShowGuidedTourConsentCard(false)}
      >
        No
      </Button>
    </SpeechBubbleCard>
  );
};

interface SpeechBubbleCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: ReactNode;
}

const SpeechBubbleCard: FC<SpeechBubbleCardProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <div className={`${styles.GuidedTourContainer} ${className}`} {...rest}>
      {children}
    </div>
  );
};

const StepsControllBar = () => {
  const { stopGuidedTour } = useHandleLeaveGuidedTour();
  const [currentStep, setCurrentStep] = useAtom(currentGuidedTourStepAtom);

  return createPortal(
    <FixedContainer position="topCenter" style={{ width: '100%' }}>
      <Stepper activeStep={currentStep}>
        {GuidedTourStepsData.map((step) => {
          return (
            <Step
              //@ts-ignore
              styleConfig={{
                activeBgColor: '#254a91',
                completedBgColor: '#233c6a',
              }}
              key={step.url}
              className={styles.Step}
              // label={step.stepLabel}
              label={''}
            />
          );
        })}
      </Stepper>

      <div className="d-flex gap-6 justify-content-center">
        <Button
          className="rounded-5"
          onClick={() => {
            stopGuidedTour();
          }}
        >
          <span className="me-1">
            <FontAwesomeIcon icon={faArrowLeftFromBracket} />
          </span>
          Leave Guded Tour
        </Button>

        <div className="d-flex gap-2">
          <Button
            disabled={currentStep === 0}
            className="rounded-5"
            onClick={() => setCurrentStep(Math.max(currentStep - 1, 0))}
          >
            Prev Step
          </Button>
          <Button
            className="rounded-5"
            onClick={() => {
              if (currentStep < GuidedTourStepsData.length - 1) {
                setCurrentStep(
                  Math.min(currentStep + 1, GuidedTourStepsData.length - 1)
                );
              }
              if (currentStep === GuidedTourStepsData.length - 1) {
                stopGuidedTour();
              }
            }}
          >
            Next Step
          </Button>
        </div>
      </div>
    </FixedContainer>,
    document.body
  );
};

const GuidedTourStepsData = [
  {
    url: '/virtual-trade-show/exhibition/2/1',
    // stepId: '2',
    // viewCorner: '1',
    stepLabel: 'Optimum Nutration Stand',
    content:
      'you can go to favorite brands and benefit from features! Do you know optimum Nutration already ? ',
  },
  {
    url: '/virtual-trade-show/exhibition/3/2',
    stepLabel: '2',
    content:
      'you can go to favorite brands and benefit from features! Do you know Per4m Nutration already ?',
  },
  {
    url: '/virtual-trade-show/exhibition/4/3',
    stepLabel: '3',
    content:
      'you can go to favorite brands and benefit from features! Do you know Peak Nutration already ?',
  },
  {
    url: '/virtual-trade-show/exhibition/5/4',
    stepLabel: '4',
    content:
      'you can go to favorite brands and benefit from features! Do you know Foodspring Nutration already ?',
  },
  {
    url: '/virtual-trade-show/exhibition/8/2',
    stepLabel: '5',
    content:
      'you can go to favorite brands and benefit from features! Do you know Peak Nutration already ?',
  },
  {
    url: '/virtual-trade-show/exhibition/9/3',
    stepLabel: '6',
    content:
      'you can go to favorite brands and benefit from features! Do you know Peak Nutration already ?',
  },
  {
    url: '/virtual-trade-show/exhibition/10/1',
    stepLabel: '7',
    content:
      'you can go to favorite brands and benefit from features! Do you know Peak Nutration already ?',
  },
  {
    url: '/virtual-trade-show/exhibition/11/3',
    stepLabel: '8',
    content:
      'you can go to favorite brands and benefit from features! Do you know Peak Nutration already ?',
  },
];
